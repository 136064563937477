<template>
  <v-container fluid class="mt-5">
    <v-row class="py-0 d-flex justify-space-around">
      <v-col cols="2" class="d-flex align-center">
        <h1>Reporte</h1>
      </v-col>
      <v-col class="py-0" cols="2">
        <v-menu
          v-model="fechaDesde"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Desde"
              v-model="fechaSeleccionadaDesde"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaSeleccionadaDesde"
            @input="fechaDesde = false"
            :max="fechaHoy"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- <v-spacer></v-spacer> -->
      <v-col class="py-0" cols="2">
        <v-menu
          v-model="fechaHasta"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Hasta"
              v-model="fechaSeleccionadaHasta"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaSeleccionadaHasta"
            @input="fechaHasta = false"
            :max="fechaHoy"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-center">
        <v-btn color="warning" elevation="2" @click="buscarPorRangoFecha"
          >Buscar</v-btn
        >
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="2" class="d-flex align-center justify-end">
        <v-btn
          color="success"
          elevation="2"
          :loading="cargandoHojaDeCalculo"
          @click="descargarHojaDeCalculo"
          ><v-icon left>mdi-microsoft-excel</v-icon>Descargar</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :sort-by="['fecha_carga']"
      :sort-desc="[true]"
      :headers="headers"
      :items="casos"
      :items-per-page="25"
      class="elevation-1 mt-5"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 100, -1],
      }"
      :loading="cargandoTabla"
      loading-text="Cargando datos...espere un momento"
      dense
    >
      <template v-slot:[`item.trackin_link`]="{ item }">
        <a :href="item.trackin_link" target="_blank">
          <v-btn x-small elevation="2" color="#1CACFC">Ver</v-btn>
        </a>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      casos: [],
      cargandoTabla: false,
      cargandoHojaDeCalculo: false,
      fechaDesde: false,
      fechaHasta: false,
      fechaSeleccionadaDesde: "",
      fechaSeleccionadaHasta: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      fechaHoy: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      headers: [
        {
          text: "Fecha",
          value: "fecha_carga",
          align: "start",
          sortable: false,
        },
        { text: "Caso", value: "order_id" },
        { text: "Tipo Venta", value: "tipo_venta" },
        { text: "Tipo Entrega", value: "tipo_entrega" },
        { text: "Cliente", value: "nombre_cliente" },
        { text: "RUT", value: "rut" },
        { text: "Comuna", value: "comuna" },
        { text: "Estado", value: "estado" },
        { text: "IMEI Sim Card", value: "ImeiSimCard" },
        { text: "Tracking Link", value: "trackin_link" },
      ],
    };
  },
  methods: {
    cargaInicial() {
      function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result.toISOString().substr(0, 10);
      }

      this.fechaSeleccionadaDesde = addDays(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
        7
      );
    },
    async buscarPorRangoFecha() {
      try {
        this.cargandoTabla = true;
        this.casos = [];
        // console.log(this.fechaSeleccionadaDesde);
        // console.log(this.fechaSeleccionadaHasta);

        if (this.fechaSeleccionadaDesde > this.fechaSeleccionadaHasta) {
          alert("Recuerda que la fecha inicial debe ser inferior a la final!");
        } else {
          const fechas = {
            fechaini: this.fechaSeleccionadaDesde,
            fechafin: this.fechaSeleccionadaHasta,
          };

          console.log("Enviando request...");
          const respuesta = await axios.post(
            "https://us-central1-rayo-dev.cloudfunctions.net/Dataclaro_Api/claroDetalle",
            fechas
          );

          console.log(respuesta.status);
          this.casos = respuesta.data;
        }
        this.cargandoTabla = false;
      } catch (error) {
        console.log(error);
        this.cargandoTabla = false;
      }
    },
    async descargarHojaDeCalculo() {
      try {
        this.cargandoHojaDeCalculo = true;
        console.log("Generando archivo...");

        if (this.fechaSeleccionadaDesde > this.fechaSeleccionadaHasta) {
          alert("Recuerda que la fecha inicial debe ser inferior a la final!");
        } else {
          const fechas = {
            fechaini: this.fechaSeleccionadaDesde,
            fechafin: this.fechaSeleccionadaHasta,
          };

          console.log("Enviando request CSV...");
          const respuesta = await axios.post(
            "https://us-central1-rayo-dev.cloudfunctions.net/Dataclaro_Api/claroReporte",
            fechas
          );

          if (respuesta.status === 200) {
            console.log(respuesta.status);
            console.log("Descargando data...");
          }

          let csvFileName = `Reporte_${this.fechaSeleccionadaDesde}_${this.fechaSeleccionadaHasta}.csv`;
          let csvData = "";
          csvData = respuesta.data;
          let csvElement = document.createElement("a");

          csvElement.href =
            "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvData);
          csvElement.download = csvFileName;
          csvElement.click();
        }
        this.cargandoHojaDeCalculo = false;
      } catch (error) {
        console.log(error);
        this.cargandoHojaDeCalculo = false;
      }
    },
  },
  mounted() {
    this.cargaInicial();
    this.buscarPorRangoFecha();
    this.crearEtiquetas();
  },
};
</script>
<style scoped>
a:link {
  text-decoration: none;
}
</style>
