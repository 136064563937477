var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-0 d-flex justify-space-around"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"2"}},[_c('h1',[_vm._v("Reporte")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Desde","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaSeleccionadaDesde),callback:function ($$v) {_vm.fechaSeleccionadaDesde=$$v},expression:"fechaSeleccionadaDesde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}},[_c('v-date-picker',{attrs:{"max":_vm.fechaHoy},on:{"input":function($event){_vm.fechaDesde = false}},model:{value:(_vm.fechaSeleccionadaDesde),callback:function ($$v) {_vm.fechaSeleccionadaDesde=$$v},expression:"fechaSeleccionadaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hasta","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaSeleccionadaHasta),callback:function ($$v) {_vm.fechaSeleccionadaHasta=$$v},expression:"fechaSeleccionadaHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}},[_c('v-date-picker',{attrs:{"max":_vm.fechaHoy},on:{"input":function($event){_vm.fechaHasta = false}},model:{value:(_vm.fechaSeleccionadaHasta),callback:function ($$v) {_vm.fechaSeleccionadaHasta=$$v},expression:"fechaSeleccionadaHasta"}})],1)],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"warning","elevation":"2"},on:{"click":_vm.buscarPorRangoFecha}},[_vm._v("Buscar")])],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"success","elevation":"2","loading":_vm.cargandoHojaDeCalculo},on:{"click":_vm.descargarHojaDeCalculo}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v("Descargar")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"sort-by":['fecha_carga'],"sort-desc":[true],"headers":_vm.headers,"items":_vm.casos,"items-per-page":25,"footer-props":{
      itemsPerPageOptions: [25, 50, 100, -1],
    },"loading":_vm.cargandoTabla,"loading-text":"Cargando datos...espere un momento","dense":""},scopedSlots:_vm._u([{key:"item.trackin_link",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":item.trackin_link,"target":"_blank"}},[_c('v-btn',{attrs:{"x-small":"","elevation":"2","color":"#1CACFC"}},[_vm._v("Ver")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }